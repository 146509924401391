<!-- =========================================================================================
    File Name: ChatNavbar.vue
    Description: Chat Application - Chat navbar
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div v-if="userId != null" class="chat__header">
    <vs-navbar class="p-4 flex navbar-custom" color="white" type="flat">
      <div class="relative flex mr-4">
        <vs-avatar class="m-0 border-2 border-solid border-white" size="40px" :src="getUserImage(userData.userId)" />
      </div>
      <h6>{{ getUserName(userData.userId) }}</h6>
      <vs-spacer></vs-spacer>
      <feather-icon
        v-if="this.$route.name === 'ApplicationIn' || this.$route.name === 'Dashboard'"
        icon="XIcon"
        class="cursor-pointer"
        @click.stop="close"
      ></feather-icon>
      <feather-icon v-else icon="XIcon" class="cursor-pointer sm:hidden" @click.stop="close"></feather-icon>
    </vs-navbar>
  </div>
</template>
<!--eslint-disable vue/custom-event-name-casing-->

<!-- eslint-disable vue/no-mutating-props -->
<!-- TODO FIX THIS -->
<script>
import contacts from './contacts';

export default {
  props: {
    userId: {
      type: String,
      default: null,
    },
    isPinnedProp: {
      type: Boolean,
      required: true,
    },
    isSidebarCollapsed: {
      type: Boolean,
      required: true,
    },
    userData: {
      type: Object,
      default: null,
    },
    extendedProfile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contacts: contacts,
      isPinnedLocal: this.isPinnedProp,
    };
  },
  computed: {
    contactIndex() {
      return contacts.findIndex((contact) => contact.id == this.userId);
    },
    userImg() {
      if (this.contactIndex === -1) {
        return this.$store.state.AppActiveUser.img;
      } else {
        return this.contacts[this.contactIndex].img;
      }
    },
    contactName() {
      if (this.contactIndex === -1) {
        return this.$store.state.AppActiveUser.name;
      } else {
        return this.contacts[this.contactIndex].name;
      }
    },
  },
  watch: {
    isPinnedProp(val) {
      this.isPinnedLocal = val;
    },
  },
  methods: {
    getUserName(userId) {
      let user = this.$store.getters['userModule/getUser'][userId];
      if (user.firstName && user.firstName !== '' && user.lastName && user.lastName !== '') {
        return `${user.firstName} ${user.lastName}`;
      } else {
        return user.fullName;
      }
    },
    getUserImage(userId) {
      let user = this.$store.getters['userModule/getUser'][userId];
      return user.profileImage;
    },
    close() {
      this.$emit('close-chat');
    },
    toggleIsPinned() {
      const chatData = this.$store.getters['chatModule/chatDataOfUser'](this.userId);
      if (chatData) {
        const payload = { id: this.userId, value: !this.isPinnedLocal };
        this.$store.dispatch('chatModule/toggleIsPinned', payload);
      } else {
        this.$emit('toggleIsChatPinned', !this.isPinnedLocal);
      }
      this.isPinnedLocal = !this.isPinnedLocal;
    },
  },
};
</script>
