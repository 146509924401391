<!-- =========================================================================================
    File Name: ChatLog.vue
    Description: Chat Application - Log of chat
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<!-- hasSentPreviousMsg -->
<template>
  <div v-if="chatData" id="component-chat-log" class="m-8">
    <div v-for="(msg, index) in chatData.messages" :key="index" class="msg-grp-container">
      <!-- If previouse msg is older than current time -->
      <template v-if="chatData.messages[index - 1]">
        <vs-divider v-if="msg.lastSentBy === chatData.employerId && !isSameDay(msg.createdAt, chatData.messages[index - 1].createdAt)">
          <span>{{ toDate(msg.createdAt) }}</span>
        </vs-divider>
        <div v-if="msg.senderId === chatData.employerId" class="spacer mt-8"></div>
      </template>

      <div class="flex items-start" :class="[{ 'flex-row-reverse': msg.senderId === chatData.employerId }]">
        <template v-if="chatData.messages[index - 1]">
          <template>
            <vs-avatar
              size="40px"
              class="m-0 flex-shrink-0"
              style="object-fit: cover"
              :class="msg.senderId === chatData.employerId ? 'sm:ml-5 ml-3' : 'sm:mr-2 mr-3'"
              :src="senderImg(msg.senderId)"
            ></vs-avatar>
          </template>
        </template>

        <template v-if="index == 0">
          <vs-avatar
            size="40px"
            class="m-0 flex-shrink-0"
            :class="msg.senderId === chatData.employerId ? 'sm:ml-5 ml-3' : 'sm:mr-5 mr-3'"
            :src="senderImg(msg.senderId)"
          ></vs-avatar>
        </template>

        <template v-if="chatData.messages[index - 1]">
          <div
            v-if="!(msg.senderId === chatData.employerId || !isSameDay(chatData.createdAt, chatData.messages[index - 1].createdAt))"
            class="mr-5"
          ></div>
        </template>

        <div
          class="msg break-words relative shadow-md rounded py-3 px-4 mb-2 rounded-lg max-w-sm"
          :class="{
            'bg-primary-gradient text-white': msg.senderId === chatData.employerId,
            'border border-solid border-grey-light bg-white': msg.senderId === chatData.userId,
          }"
        >
          <span>{{ msg.message }}</span>
          <br />
          <br />
          <span class="message-timestamp"
            ><div class="flex items-center gap-2">
              {{ toDate(msg.createdAt) }}
              <svg
                v-if="msg.senderId === chatData.employerId"
                xmlns="http://www.w3.org/2000/svg"
                width="17px"
                height="17px"
                viewBox="0 0 122.88 74.46"
                :fill="msg.read ? '#349beb' : '#e2e2e2'"
              >
                <path
                  d="M1.87,47.2a6.33,6.33,0,1,1,8.92-9c8.88,8.85,17.53,17.66,26.53,26.45l-3.76,4.45-.35.37a6.33,6.33,0,0,1-8.95,0L1.87,47.2ZM30,43.55a6.33,6.33,0,1,1,8.82-9.07l25,24.38L111.64,2.29c5.37-6.35,15,1.84,9.66,8.18L69.07,72.22l-.3.33a6.33,6.33,0,0,1-8.95.12L30,43.55Zm28.76-4.21-.31.33-9.07-8.85L71.67,4.42c5.37-6.35,15,1.83,9.67,8.18L58.74,39.34Z"
                />
              </svg> </div
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import contacts from './contacts';

export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
    chatData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contacts: contacts,

      // chatData: this.$store.getters["chatModule/chatDataOfUser"](this.userId)
    };
  },
  computed: {
    // chatData() {
    //   console.log(
    //     this.$store.getters["chatModule/chatDataOfUser"](this.userId)
    //   );

    //   return this.$store.getters["chatModule/chatDataOfUser"](this.userId);
    // },
    contactIndex() {
      return contacts.findIndex((contact) => contact.id == this.userId);
    },
    userImg() {
      if (this.contactIndex !== -1) {
        return this.contacts[this.contactIndex].img;
      } else {
        return null;
      }
    },
    activeUserImg() {
      return this.$store.state.AppActiveUser.img;
    },

    hasSentPreviousMsg() {
      return (last_sender, current_sender) => last_sender == current_sender;
    },
  },
  updated() {
    this.scrollToBottom();
  },
  mounted() {
    this.scrollToBottom();
  },
  methods: {
    isSameDay(time_to, time_from) {
      let date_time_to = new Date(Date.parse(time_to));
      let date_time_from = new Date(Date.parse(time_from));

      if (date_time_to.toString() === 'Invalid Date') {
        date_time_to = new Date(time_to);
        date_time_from = new Date(Date.parse(time_from.toDate()));
      }

      return (
        date_time_to.getFullYear() === date_time_from.getFullYear() &&
        date_time_to.getMonth() === date_time_from.getMonth() &&
        date_time_to.getDate() === date_time_from.getDate()
      );
    },
    senderImg(senderId) {
      if (senderId === this.chatData.userId) {
        return this.chatData.userData.userImage;
      } else {
        return this.chatData.jobData.jobImage;
      }
    },
    toDate(time) {
      const locale = 'en-us';

      if (typeof time.toDate !== 'undefined') {
        const monthName = time.toDate().toLocaleString(locale, {
          month: 'short',
        });
        const date_obj = new Date(Date.parse(time.toDate()));
        return this.formatDateTime(date_obj, monthName);
      } else {
        const monthName = time.toLocaleString(locale, {
          month: 'short',
        });
        const date_obj = new Date(Date.parse(time));
        return this.formatDateTime(date_obj, monthName);
      }
    },
    formatDateTime(date_obj, monthName) {
      return date_obj.getDate() + ' ' + monthName + ' ' + date_obj.getHours() + ':' + date_obj.getMinutes();
    },
    scrollToBottom() {
      this.$nextTick(() => {
        this.$parent.$el.scrollTop = this.$parent.$el.scrollHeight;
      });
    },
  },
};
</script>
<style lang="scss">
.message-timestamp {
  color: rgb(226, 226, 226);
  float: right;
}
</style>
